@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-6xl;
  }

  h2 {
    @apply text-5xl;
  }

  h3 {
    @apply text-4xl;
  }

  h4 {
    @apply text-3xl;
  }

  h5 {
    @apply text-2xl;
  }

  p {
    @apply text-sm;
    @apply leading-loose;
  }

  small {
    @apply text-sm;
  }

  sub {
    @apply text-xs;
  }


  /* ... */
}

.font-custom {
  font-family: 'Poppins', sans-serif;
}

:root {
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body {
  background-color: #FBFBFD;
  color: #2f353e;
}