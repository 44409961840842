
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 85rem;
}

.slider {
  overflow: visible;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: black;
}


.image {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  will-change: transform, opacity;
}

.image:hover {
  cursor: grab;
}

.image:active {
  cursor: grabbing;
}

.buttons {
  margin-top: 12px;
}

.buttons button {
  outline: none;
  border: none;
  user-select: none;
  padding: 10px 12px;
  transform: skewY(-5deg) rotate(5deg);
  background-color: var(--grey);
  font-family: inherit;
  font-size: inherit;
  color: white;
  transition: 0.07s ease-out transform;
}

.buttons button:first-of-type {
  margin-right: 15px;
}

.buttons button:hover {
  cursor: pointer;
}

.buttons button:active {
  transform: scale(0.85) skewY(-5deg) rotate(5deg);
}

.thumbnails {
  display: flex;
  justify-content: center;
}

.thumbnail-container {
  position: relative;
  height: 120px;
  width: 90px;
}

.slider-content {
  max-width: 85rem;
}

.active-indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transform: scaleX(0);
  transform-origin: left;
  background-color: var(--grey);
  transition: 1s cubic-bezier(0.56, 0.03, 0.12, 1.04) transform;
}

.active-indicator.active {
  transform: scaleX(1);
}

.carousel-content {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel-item {
  flex: 0 0 auto;
  margin: 0 10px; /* Adjust spacing */
  padding: 10px;
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.carousel-item.active {
  background: #dc2626;
  color: white;
}

