@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.exampleBody {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}

.exampleContainer {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
}

.example {
  width: 80%;
  height: min-content;
  stroke: #bf4d00;
  padding: 4%;
  border: 1px solid #eaeef5;
}
