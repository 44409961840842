@keyframes scroll {
    0% {
      left: 0;
    }
    100% {
      left: -150%;
    }
  }

  .animate {
    animation: scroll 25s linear infinite;
  }
  
  .animate:hover {
    animation-play-state: paused;
  }
  