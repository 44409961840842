.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.tab {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}


.tab-content {
  background-attachment: fixed;
  display: none;
  width: 100%;
  margin-top: 10px;
}

.tab-content.active {
  display: block;
}

nav ul li.bold a {
  font-weight: bold;
}